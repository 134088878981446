import React, { PropsWithChildren } from 'react';

interface ContainerProps {
  className?: string;
}

export const Container: React.FC<PropsWithChildren & ContainerProps> = ({ children }) => {
  return <div className="flex flex-col items-center w-full text-center py-20">{children}</div>;
};

export const MainTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="text-center my-0 mx-10">{children}</div>;
};

export const SubTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="text-center opacity-70 m-0 mb-2.5">{children}</div>;
};

export const GridBannerGap: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="mt-5 mb-15">{children}</div>;
};

interface StyledButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

export const StyledButton: React.FC<PropsWithChildren & StyledButtonProps> = ({
  children,
  onClick,
  disabled,
}) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className="w-full overflow-hidden flex justify-center"
    >
      {children}
    </button>
  );
};

export const ProductsPageContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="mx-auto items-center md:mb-40">{children}</div>;
};
