'use client';

import { Grid, GridItem, GridRow } from 'components/Grid';
import { IntroText } from 'components/IntroText';
import { LiquidButton } from 'components/LiquidButton';
import { useEffect } from 'react';

import NextImage from 'components/NextImage';
import { NextLink } from 'components/NextLink';
import NextNprogress from 'nextjs-progressbar';
import { TrackingService } from 'service/tracking/trackingService';
import { COLORS } from 'styles/constants';
import type { IErrorPage } from 'types/ErrorPage';
import { Container } from './PageLevelLayouts/Layouts';

interface NotFoundProps {
  errorData?: IErrorPage;
  locale: string;
}

const ClientNotFoundPage = ({ errorData, locale }: NotFoundProps) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      TrackingService.contentGroups({
        cg1: 'error page',
      });
      TrackingService.pageValues(
        locale.slice(3, 5) || '',
        locale.slice(0, 2).toUpperCase() || '',
        window.location.pathname || '',
      );
    }
  }, []);

  const imageWidth = errorData?.image?.asset?.[0]?.width;
  const imageHeight = errorData?.image?.asset?.[0]?.height;

  return (
    <>
      <NextNprogress
        color="#29D"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        options={{ showSpinner: false }}
      />
      <Container className="flex flex-col justify-center w-full text-center min-h-screen">
        {errorData?.image?.asset?.[0].url && (
          <Grid>
            <GridRow columns={16}>
              <GridItem colStart={6} colSpan={6}>
                <NextImage
                  src={errorData?.image?.asset?.[0].url}
                  alt={errorData?.errorType ?? ''}
                  width={imageHeight ?? 0}
                  height={imageHeight ?? 0}
                  sizes={!imageHeight || !imageWidth ? '100vw' : undefined}
                  mimeType={String(errorData?.image?.asset?.[0]?.mimeType)}
                />
              </GridItem>
            </GridRow>
          </Grid>
        )}
        <IntroText title={errorData?.title} description={errorData?.description} />
        <div className="text-center">
          <NextLink href={errorData?.link?.url || '/'}>
            <LiquidButton
              text={errorData?.link?.displayName || 'Details'}
              height={52}
              width={182}
              color={COLORS.primary}
              textColor={COLORS.white}
            />
          </NextLink>
        </div>
      </Container>
    </>
  );
};

export default ClientNotFoundPage;
